// Scripts for the video playlist promotions component
import Swiper from 'swiper';
import { Controller, A11y, Navigation, Thumbs } from 'swiper/modules';
import Player from '@vimeo/player';

Swiper.use([Controller, Navigation, A11y, Thumbs]);

// Controller slider
let videoThumbs = new Swiper(
  '.video-playlist__controller',
  {
    centerInsufficientSlides: true,
    centerSlides: true,
    loop: false,
    preloadImages: false,
    spaceBetween: 10,
    slidesPerView: 4,
    slideToClickedSlide: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true
  }
);

// Main slider
let videoPlaylist = new Swiper( // eslint-disable-line no-unused-vars
  '.video-playlist__slider',
  {
    loop: true,
    navigation: {
      nextEl: '.video-playlist__next-slide',
      prevEl: '.video-playlist__prev-slide'
    },
    preloadImages: false,
    slidesPerView: 1,
    thumbs: {
      multipleActiveThumbs: false,
      swiper: videoThumbs,
    },
  }
);

/**
 * Set up each video item
 */
const videoPlaylists = document.getElementsByClassName('video-playlist');
if( typeof videoPlaylists !== 'undefined' && videoPlaylists !== null ) {
  for( let videoPlaylist of videoPlaylists ) {

    // Identify the dialog box for the component
    const dialog = videoPlaylist.querySelector('.video-playlist__dialog');

    // Identify the video iframe
    const videoIframe = videoPlaylist.querySelector('.video-playlist__iframe');

    // Identify the slides
    const slides = videoPlaylist.getElementsByClassName('video-playlist__item');
    for( let slide of slides ) {
      /**
       * When the slide is clicked, pass the video ID to the dialog box and
       * open it
       */
      slide.addEventListener('click', function() {
        // Set up the video player
        let videoId = slide.dataset.id;
        videoIframe.src = 'https://player.vimeo.com/video/' + videoId;
        const player = new Player(videoIframe);

        // Open the dialog
        dialog.showModal();

        /**
         * When the dialog is closed or the escape button is pressed, pause
         * the video
         */
        let dialogClose = dialog.querySelector('.video-playlist__close');
        dialogClose.addEventListener('click', function() {
          closeDialog();
        });
        dialog.addEventListener('close', function() {
          closeDialog();
        });

        function closeDialog() {
          // Stop the video and destroy the player
          if( player !== null )
            player.pause().catch(function(error) {
              switch (error.name) {
                case 'PasswordError':
                  // the video is password-protected and the viewer needs to enter the
                  // password first
                  break;

                case 'PrivacyError':
                  // the video is private
                  break;

                default:
                  // some other error occurred
                  break;
              }
            });

          // Close the dialog
          dialog.close();
        }
      });
    }
  }
}
